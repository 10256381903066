import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

import LatestListings from '../../containers/Listings/LatestListings';
import FrontPageBanner from '../FrontPageBanner/FrontPageBanner';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  // Create a custom section for LatestListings
  const frontPageBannerSection = {
    sectionId: 'frontpage-banner',
    sectionType: 'frontPageBanner',
  };
  const latestListingsSection = {
    sectionId: 'latest-listings',
    sectionType: 'customListings',
  };

  // Include the custom section in the page data
  const customSections = pageData
    ? [
        frontPageBannerSection,
        latestListingsSection,
        pageData.sections[1],
        pageData.sections[2],
        pageData.sections[3],
      ]
    : [];

  return (
    <PageBuilder
      pageAssetsData={{
        ...pageData,
        sections: customSections,
      }}
      options={{
        sectionComponents: {
          frontPageBanner: { component: FrontPageBanner },
          customListings: { component: LatestListings },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};
LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
