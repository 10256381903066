import React, { useEffect, useState } from 'react';
import { array, bool, node, object, string } from 'prop-types';
//import { Carousel, ConfigProvider } from 'antd';
import css from './VideoCarousel.module.css';
import { Button } from 'antd';

// import 'react-multi-carousel/lib/styles.css';
import { PlayCircleOutlined } from '@ant-design/icons';
import ModalPreview from './ModalPreview';
const Card = props => {
  const [openModal, SetOpenModal] = useState(false);
  const [vidioSrc, SetVidioSrc] = useState(null);

  const { children, className, rootClassName, spinnerClassName, mediaItem, ...rest } = props;

  useEffect(() => {
    handleCloseVedioModal();
  }, []);
  const handleOpenVedioModal = src => {
    SetOpenModal(true);
    SetVidioSrc(src);
  };

  const handleCloseVedioModal = () => {
    SetOpenModal(false);
    SetVidioSrc(null);
  };
  return (
    <div>
      <img className={css.videoReview} alt="example" src={mediaItem.thumb} />

      <Button
        onClick={() => handleOpenVedioModal(mediaItem.video)}
        className={css.playButton}
        type="text"
        shape="circle"
        icon={<PlayCircleOutlined className={css.buttonIcon} />}
        size={'large'}
      />
      <ModalPreview
        isOpen={openModal}
        videoSrc={vidioSrc}
        handleClose={() => handleCloseVedioModal()}
      />
    </div>
  );
};

Card.defaultProps = {
  rootClassName: null,
  className: null,
  spinnerClassName: null,
  checkmarkClassName: null,
  mediaItem: object,
  children: null,
};

Card.propTypes = {
  rootClassName: string,
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,
  mediaItem: object,

  children: node,
};

export default Card;
