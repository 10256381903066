import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestListings } from './LatestListings.duck'; // Adjust the path as needed
import ListingCard from '../../components/ListingCard/ListingCard';
import styles from './LatestListings.module.css';
import { useConfiguration } from '../../context/configurationContext';

const LatestListings = props => {
  const { notitle = false, location = null, onDataFetched = null } = props;
  const config = useConfiguration();
  const { latestListings, loading, error } = useSelector(state => state.LatestListings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLatestListings(config, location));
  }, [dispatch, config, location]);

  useEffect(() => {
    if (onDataFetched && latestListings) {
      onDataFetched(latestListings);
    }
  }, [latestListings, onDataFetched]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading listings: {error}</div>;

  return (
    <div className={styles.container}>
      {!notitle && <h2 className={styles.latestListingsHeader}>Trending Desserts</h2>}
      <div className={styles.listingCards}>
        {latestListings?.map(listing => (
          <ListingCard
            className={styles.listingCard}
            key={listing.id.uuid}
            listing={listing}
            showAuthorInfo={true}
          />
        ))}
      </div>
    </div>
  );
};

export default LatestListings;
