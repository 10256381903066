import React, { useEffect, useRef } from 'react';
import { array, bool, func, node, string } from 'prop-types';
//import { Carousel, ConfigProvider } from 'antd';
import css from './VideoCarousel.module.css';
import { Modal } from 'antd';

// import 'react-multi-carousel/lib/styles.css';

const ModalPreview = props => {
  const {
    children,
    className,
    rootClassName,
    spinnerClassName,
    videoSrc,
    isOpen,
    handleClose,
    ...rest
  } = props;
  const videoRef = useRef(null);

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    handleClose();
  };
  return (
    <Modal
      styles={{
        content: { backgroundColor: '#ffffff00', boxShadow: 'none' }, // turns the Modal red
      }}
      mask={true}
      centered
      open={isOpen}
      onCancel={handlePause}
      width={350}
      footer={(_, { OkBtn, CancelBtn }) => <></>}
    >
      <video ref={videoRef} className={css.contentStyle} src={videoSrc} controls autoPlay />
    </Modal>
  );
};

ModalPreview.defaultProps = {
  rootClassName: null,
  className: null,
  spinnerClassName: null,
  checkmarkClassName: null,
  videoSrc: null,
  isOpen: false,
  handleClose: null,
  children: null,
};

ModalPreview.propTypes = {
  rootClassName: string,
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,
  videoSrc: string,
  isOpen: bool,
  handleClose: func,
  children: node,
};

export default ModalPreview;
