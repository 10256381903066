import React from 'react';
import logo from './assets/sweettooth_logo.png';
import css from './FrontPageBanner.module.css';
import TopbarSearchForm from '../../components/Topbar/TopbarSearchForm/TopbarSearchForm';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';

import VideoCarousel from '../../components/VideoCarousel/VideoCarousel';
import first_vid from '../../assets/Videos/first.MOV';
import second_vid from '../../assets/Videos/second.MOV';
import first_thumb from '../../assets/Videos/first.png';
import second_thumb from '../../assets/Videos/second.png';
const FrontPageBanner = () => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();

  const handleSubmit = values => {
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = topbarSearchParams();
    const url = createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams);
    // Redirect to the generated URL
    window.location.href = url;
  };

  const search = (
    <div className={css.searchBarContainer}>
      <h1>Find a dessert.</h1>
      <p className={css.searchPrompt}>What are you looking for?</p>
      <TopbarSearchForm
        className={css.searchLink}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={handleSubmit}
        appConfig={config}
      />
    </div>
  );
  const videosList = [
    { video: first_vid, thumb: first_thumb },
    { video: second_vid, thumb: second_thumb },
  ];
  return (
    <div className={css.container}>
      <div className={css.contentContainer}>
        <h1>WELCOME TO</h1>
        <div className={css.logoContainer}>
          <img src={logo} alt="Sweet Tooth Logo" className={css.logo} />
        </div>
        <h1>YOUR DESTINATION FOR CUSTOM DESSERTS FROM DIVERSE BAKERIES!</h1>
        <div className={css.searchContainer}>{search}</div>
        {/* <div className={css.designContainer}>
        <div className={css.designBox}>
          <img src={cake_image}  alt="Design" className={css.designImage} />
          <a href='/s?pub_categorySingleSelect=cakes'><button className={css.button}>CAKES</button></a>
        </div>
        <div className={css.designBox}>
          <img src={cookie_image} alt="Design" className={css.designImage} />
          <a href='/s?pub_categorySingleSelect=Cookies'><button className={css.button}>SUGAR COOKIES</button></a>
        </div>
      </div> */}
        <div>
          {' '}
          <a href="/s">
            <button className={css.browseButton}>BROWSE ALL LISTINGS</button>
          </a>
        </div>
        <VideoCarousel videosList={videosList} />
      </div>
    </div>
  );
};

export default FrontPageBanner;
